<template>
  <div class="sign_in">
    <div class="return_icon">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
    </div>
    <div class="sign_in_main">
      <div class="sign_in_title1">您好！</div>
      <div class="sign_in_title2">欢迎您参加面试</div>
      <div class="sign_in_title3">您的面试时间为</div>
      <div class="sign_in_title4">2023年12月23日 12:34 - 13:34</div>
      <div class="sign_box">
        <div class="sign_btn" @click="$router.go(-1)">签到</div>
        <div class="sign_btn_btm">
          <i class="el-icon-location-outline"></i>
          您已进入可签到区域
        </div>
      </div>
    </div>

    <div>
      高德
      <div id="container"></div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      // 地图对象
      AMap: null,
      // 地图实例对象
      map: null,
      // 经纬度
      location: {
        lat: "",
        lng: "",
      },
      geolocation: {},
    };
  },
  unmounted() {
    this.map?.destroy();
  },
  mounted() {
    this.initAMap();
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "10fb2bcc51fa73e1da3bcf0776be2b17", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            zoom: 13, // 初始化地图级别
          });
          console.log(this.map.getCenter());
          console.log(this.map);

          const geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：无穷大
          });
          geolocation.getCurrentPosition();
          AMap.event.addListener(geolocation, "complete", res=>{
            console.log(res);
          });
          AMap.event.addListener(geolocation, "error", res=>{
            console.log(res);
          });
          
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.sign_in {
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: #fff;
}
.return_icon {
  height: 2.7rem;
  line-height: 2.7rem;
}
.sign_in_main {
  box-sizing: border-box;
  padding: 0 2rem;
}
.sign_in_title1 {
  color: #5b5b5b;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2.5rem;
}
.sign_in_title2 {
  color: #a9a9a9;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.sign_in_title3 {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 5rem;
}
.sign_in_title4 {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.sign_box {
  margin-top: 5rem;
  text-align: center;
}
.sign_btn {
  display: inline-block;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 6.25rem;
  color: #fff;
  font-size: 1.5rem;
  background: linear-gradient(313deg, #18aef3 -66.5%, #0a76e2 83.19%);
}
.sign_btn_btm {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 1.25rem;
}
.box_map {
  width: 100%;
  height: 300px;
  background: pink;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
</style>
